const AboutCard = () => {
  return (
    <div className="mr-auto ml-auto -mt-1 md:mt-[3rem] flex flex-col rounded-lg shadow-xl px-[1rem] md:px-[3rem] py-4 h-[28rem] md:h-[20rem] max-h-[28rem] md:max-h-[20rem] bg-white max-w-2xl overflow-y-scroll no-scrollbar">
      <p className="text-3xl font-bold mr-auto ml-auto text-[#000000bf] text-center mb-2">
        About Onics
      </p>
      <p className="text-[#0000009e] text-lg font-semibold mb-2 text-center">
        Onics Store: Your One-Stop Shop for All Your Grocery Needs
      </p>
      <p className="text-[#0000009e] text-lg">
        In the bustling modern world, convenience and efficiency are paramount,
        and Onics Store stands at the forefront of providing an unparalleled
        shopping experience for your favorite groceries. Imagine a place where
        you can find an extensive range of products tailored to your unique
        preferences and dietary requirements, all within the confines of a
        user-friendly digital storefront.
      </p>
    </div>
  );
};

export default AboutCard;
