function Ellipse1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="799"
      height="709"
      viewBox="0 0 1988 1909"
      fill="none"
    >
      <path
        d="M1987.29 952.401C1987.29 1539.62 1605.63 1908.95 996.156 1908.95C386.685 1908.95 0.247314 1539.62 0.247314 952.401C0.247314 365.184 386.685 0.530518 996.156 0.530518C1605.63 0.530518 1987.29 365.184 1987.29 952.401ZM0.247314 952.401C0.247314 1661.86 498.407 1908.95 996.156 1908.95C1493.9 1908.95 1987.29 1642.2 1987.29 952.401C1987.29 262.599 1493.9 0.530518 996.156 0.530518C306.306 0.530518 0.247314 472.827 0.247314 952.401Z"
        fill="#FBB80D"
        fill-opacity="0.9"
      />
    </svg>
  );
}

export default Ellipse1;
