function AddToCartIcon({ color, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '16'}
      height={height ? height : '20'}
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        d="M15.8091 11.6214H18.7963V7.53072H23.277V4.80359H18.7963V0.712891H15.8091V4.80359H11.3284V7.53072H15.8091V11.6214ZM9.83481 23.8935C8.19187 23.8935 6.86259 25.1207 6.86259 26.6206C6.86259 28.1206 8.19187 29.3478 9.83481 29.3478C11.4777 29.3478 12.822 28.1206 12.822 26.6206C12.822 25.1207 11.4777 23.8935 9.83481 23.8935ZM24.7706 23.8935C23.1276 23.8935 21.7984 25.1207 21.7984 26.6206C21.7984 28.1206 23.1276 29.3478 24.7706 29.3478C26.4135 29.3478 27.7577 28.1206 27.7577 26.6206C27.7577 25.1207 26.4135 23.8935 24.7706 23.8935ZM10.0887 19.4619L10.1335 19.2983L11.4777 17.0757H22.6049C23.7251 17.0757 24.7108 16.5166 25.2186 15.6712L30.9838 6.11261L28.385 4.80359H28.3701L26.7272 7.53072L22.6049 14.3486H12.12L11.9258 13.9804L8.5802 7.53072L7.16131 4.80359L5.75735 2.07646H0.873352V4.80359H3.8605L9.23738 15.1531L7.22105 18.4938C6.98208 18.8756 6.84766 19.3256 6.84766 19.8028C6.84766 21.3027 8.19187 22.5299 9.83481 22.5299H27.7577V19.8028H10.4621C10.2679 19.8028 10.0887 19.6528 10.0887 19.4619Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
}

export default AddToCartIcon;
