function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 38 38"
      fill="none"
    >
      <path
        d="M33.25 10.1307C32.433 11.3182 31.4687 12.3204 30.3181 13.1743C30.3549 13.4343 30.3549 13.6944 30.3549 13.9533C30.3549 21.672 24.491 30.5783 13.7299 30.5783C10.4274 30.5783 7.34706 29.576 4.74881 27.9432C5.19412 27.98 5.67625 28.0548 6.15956 28.0548C8.90625 28.0548 11.3917 27.0894 13.3962 25.5314C10.7979 25.4946 8.683 23.7501 7.94081 21.4488C8.2745 21.5236 8.683 21.5604 9.05468 21.5604C9.57481 21.5604 10.1306 21.4856 10.6139 21.3371C7.90518 20.817 5.90068 18.48 5.90068 15.6597V15.5849C6.71768 15.9934 7.57031 16.2534 8.53575 16.2903C6.97656 15.2144 5.9375 13.4331 5.9375 11.4286C5.9375 10.3527 6.23437 9.3505 6.7165 8.45988C9.61162 11.9856 13.9163 14.3606 18.7767 14.5826C18.7019 14.1741 18.6283 13.692 18.6283 13.2467C18.6283 10.0179 21.2266 7.38281 24.4542 7.38281C26.1618 7.38281 27.683 8.125 28.7589 9.27569C30.0948 9.01562 31.3191 8.49669 32.433 7.82813C32.0245 9.20088 31.0971 10.3884 29.9096 11.0938C31.0971 10.9453 32.1729 10.6116 33.25 10.1283V10.1307Z"
        fill="white"
      />
    </svg>
  );
}

export default TwitterIcon;
