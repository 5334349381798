function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 37 36"
      fill="none"
    >
      <path
        d="M18.5 3.06055C10.0208 3.06055 3.08331 9.79555 3.08331 18.0906C3.08331 25.5906 8.72581 31.8156 16.095 32.9406V22.4406H12.1791V18.0906H16.095V14.7755C16.095 11.0105 18.3921 8.94055 21.9225 8.94055C23.6029 8.94055 25.3604 9.22555 25.3604 9.22555V12.9305H23.4179C21.5062 12.9305 20.905 14.0855 20.905 15.2706V18.0906H25.1908L24.4971 22.4406H20.905V32.9406C24.5378 32.3823 27.8459 30.5788 30.2319 27.8556C32.6179 25.1325 33.925 21.669 33.9166 18.0906C33.9166 9.79555 26.9791 3.06055 18.5 3.06055Z"
        fill="white"
      />
    </svg>
  );
}

export default FacebookIcon;
