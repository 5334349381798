function Ellipse2() {
  return (
    <svg
      width="799"
      height="709"
      viewBox="0 0 1988 1909"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1987.65 952.267C1987.65 1539.48 1605.99 1908.82 996.516 1908.82C387.046 1908.82 0.607666 1539.48 0.607666 952.267C0.607666 365.05 387.046 0.395996 996.516 0.395996C1605.99 0.395996 1987.65 365.05 1987.65 952.267ZM0.607666 952.267C0.607666 1661.72 498.768 1908.82 996.516 1908.82C1494.26 1908.82 1987.65 1642.07 1987.65 952.267C1987.65 262.465 1494.26 0.395996 996.516 0.395996C306.666 0.395996 0.607666 472.692 0.607666 952.267Z"
        fill="white"
      />
    </svg>
  );
}

export default Ellipse2;
