function CartIcon({ height, width, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '35'}
      height={height ? height : '30'}
      viewBox="0 0 81 81"
      fill="none"
    >
      <g clip-path="url(#clip0_16_362)">
        <path
          d="M54.1371 63.5211C50.4503 63.5211 47.4943 66.4771 47.4943 70.1638C47.4943 71.9256 48.1942 73.6152 49.4399 74.8609C50.6857 76.1067 52.3753 76.8065 54.1371 76.8065C55.8988 76.8065 57.5884 76.1067 58.8342 74.8609C60.0799 73.6152 60.7798 71.9256 60.7798 70.1638C60.7798 66.4771 57.7906 63.5211 54.1371 63.5211ZM0.995148 10.3792V17.0219H7.63789L19.5948 42.2311L15.0778 50.3684C14.5795 51.2984 14.2806 52.3945 14.2806 53.5569C14.2806 55.3187 14.9805 57.0083 16.2262 58.2541C17.472 59.4998 19.1616 60.1997 20.9234 60.1997H60.7798V53.5569H22.3183C22.0981 53.5569 21.8869 53.4695 21.7312 53.3137C21.5755 53.158 21.488 52.9468 21.488 52.7266C21.488 52.5605 21.5212 52.4277 21.5876 52.328L24.5769 46.9142H49.3211C51.8121 46.9142 54.0042 45.5192 55.1335 43.4932L67.024 22.0039C67.2565 21.4725 67.4225 20.9079 67.4225 20.3433C67.4225 19.4624 67.0726 18.6176 66.4497 17.9947C65.8268 17.3718 64.982 17.0219 64.1012 17.0219H14.9781L11.856 10.3792H0.995148ZM20.9234 63.5211C17.2366 63.5211 14.2806 66.4771 14.2806 70.1638C14.2806 71.9256 14.9805 73.6152 16.2262 74.8609C17.472 76.1067 19.1616 76.8065 20.9234 76.8065C22.6851 76.8065 24.3747 76.1067 25.6205 74.8609C26.8662 73.6152 27.5661 71.9256 27.5661 70.1638C27.5661 66.4771 24.5769 63.5211 20.9234 63.5211Z"
          fill={color ? color : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_16_362">
          <rect
            width="79.7129"
            height="79.7129"
            fill="white"
            transform="translate(0.995155 0.414795)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CartIcon;
