function SearchIcon({ height, width, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '20'}
      height={height ? height : '15'}
      viewBox="0 0 59 52"
      fill="none"
    >
      <path
        d="M41.6828 32.2962H39.055L38.1236 31.5178C41.3834 28.2314 43.346 23.9648 43.346 19.3235C43.346 8.97419 33.6664 0.585205 21.7249 0.585205C9.78336 0.585205 0.10376 8.97419 0.10376 19.3235C0.10376 29.6728 9.78336 38.0618 21.7249 38.0618C27.0802 38.0618 32.0032 36.3609 35.7952 33.5358L36.6933 34.343V36.6204L53.3249 51.0056L58.2812 46.7102L41.6828 32.2962ZM21.7249 32.2962C13.4423 32.2962 6.75641 26.5017 6.75641 19.3235C6.75641 12.1453 13.4423 6.35083 21.7249 6.35083C30.0074 6.35083 36.6933 12.1453 36.6933 19.3235C36.6933 26.5017 30.0074 32.2962 21.7249 32.2962Z"
        fill={color ? color : '#1A4C6E'}
        fill-opacity={color === '#FFF' ? '1' : '0.68'}
      />
    </svg>
  );
}

export default SearchIcon;
