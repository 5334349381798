function LikedIcon() {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFB800"
    >
      {/* <title>heart-like-solid</title> */}
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="icons_Q2" data-name="icons Q2">
          <path d="M34,4c-3.9,0-8,3.1-10,6.5C22,7.1,17.9,4,14,4A12,12,0,0,0,5.5,7.6,11.9,11.9,0,0,0,2,16.1c0,4.1,2.7,7.6,5,10.2L23.3,43.7a1,1,0,0,0,1.4,0L41,26.3c2.3-2.6,5-6.1,5-10.2A12.1,12.1,0,0,0,34,4Z" />
        </g>
      </g>
    </svg>
  );
}

export default LikedIcon;
